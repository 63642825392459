import { flow, makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import {
  getTypesList,
  fetchDirectoryAttributesList,
  fetchDirectory,
  fetchJoinedDirectory,
} from 'src/api/directory/requests';
import { isDynamicJoin } from 'src/shared/utils/is-dynamic-join';

import { I18NextStore } from '../i18next/i18next-store';
import { NotificationsStore } from '../notifications-store/notifications-store';

import {
  TDirectoryTypeAttribute,
  TDirectoriesAttributesDictionary,
  TDirectoryTypeItem,
  TDirectories,
  TDirectoryItem,
  TRefQuery,
} from './types';

export class Directories {
  @observable typesList: TDirectoryTypeItem[] = [];
  @observable attributes: TDirectoriesAttributesDictionary = {};
  @observable directories: TDirectories = {};
  @observable isLoading: boolean = false;
  @observable joinedObjects: Record<string, Record<string, string | number | boolean>[]> = {};
  readonly i18: I18NextStore;
  readonly notifications: NotificationsStore;

  constructor(i18: I18NextStore, notifications: NotificationsStore) {
    this.i18 = i18;
    this.notifications = notifications;

    makeObservable(this);
  }

  getLabelByObjectTypeAndFieldId = computedFn((attrName): string => {
    const [objectType, fieldId] = attrName.split('.');

    if (!objectType || !fieldId) {
      return this.i18.t('directory:Errors.unrecFieldName');
    }

    const attributes = this.getTypeAttributes(objectType);

    const name = attributes.find((attr) => attr.name === fieldId)?.defaultLabel;

    return name || this.i18.t('directory:Errors.unrecFieldName');
  });

  getAttribute(key?: string): TDirectoryTypeAttribute | undefined {
    if (!key) {
      return undefined;
    }

    return this.attributes[key];
  }

  getTypeAttributes(type: string): TDirectoryTypeAttribute[] {
    const filteredAttributes = Object.entries(this.attributes).filter(([attrKey]) => attrKey.split('.')[0] === type);

    return filteredAttributes.map(([, value]) => value);
  }

  getDirectory(key?: string): TDirectoryItem[] {
    if (!key) return [];
    return this.directories[key] || [];
  }

  getJoinedObject(refQuery?: TRefQuery): Record<string, string | number | boolean>[] | null {
    if (!refQuery) return null;
    const serializedRefQuery = JSON.stringify(refQuery);
    return this.joinedObjects[serializedRefQuery] || null;
  }

  private async fetchAttributes(typesList: TDirectoryTypeItem[]): Promise<TDirectoriesAttributesDictionary> {
    const _attributes: TDirectoriesAttributesDictionary = {};
    try {
      const res = await fetchDirectoryAttributesList(typesList);
      res.forEach((typeAttributes, index) => {
        const type = typesList[index];
        typeAttributes.forEach((attribute) => {
          _attributes[`${type.name}.${attribute.name}`] = attribute;
        });
      });
    } catch (error) {
      console.error(error);
    }

    return _attributes;
  }

  private async fetchDirectory(type: string): Promise<TDirectoryItem[] | null> {
    try {
      return await fetchDirectory(type);
    } catch (error) {
      console.error(error);
      const typeName = this.typesList.find((_type) => _type.name === type);
      this.notifications.showNotificationMessage(
        this.i18.t('directory:Errors.fetchDirectory', {
          directoryName: typeName?.label || this.i18.t('directory:Errors.unrecDirectoryName'),
        })
      );
      return null;
    }
  }

  private async fetchDirectories(types: string[]): Promise<TDirectories> {
    const _directories: TDirectories = {};

    const directories = await Promise.all(types.map((type) => this.fetchDirectory(type)));

    for (const directory of directories) {
      if (!directory || !directory.length) {
        continue;
      }

      const objectType = directory[0].objectType;

      _directories[objectType] = directory;
    }

    return _directories;
  }

  @flow.bound
  async *refreshDirectory(type: string) {
    try {
      const res = await fetchDirectory(type);
      yield;
      this.directories[type] = res;
    } catch (error) {
      console.error(error);
    }
  }

  async fetchDymanicJoinObject(
    refQuery: TRefQuery,
    values: Record<string, string | number | boolean>
  ): Promise<Record<string, string | number | boolean>[]> {
    try {
      let stringifiedRefQuery = JSON.stringify(refQuery).toString();

      const parseKey = (_: string, valueKey: string): string => {
        const value = values[valueKey];
        if (value === null) {
          console.warn('value is null', value, valueKey, refQuery);
        }
        if (typeof value === 'number' || typeof value === 'boolean' || value === null) {
          return value.toString();
        }
        return JSON.stringify(value);
      };
      stringifiedRefQuery = stringifiedRefQuery.replace(/"[$%]{([0-9A-Za-z.-_]+)}"/g, parseKey);

      stringifiedRefQuery = stringifiedRefQuery.replace(/(,{.*"value":(undefined|null)[^{}]*})/g, '');

      return await fetchJoinedDirectory(JSON.parse(stringifiedRefQuery));
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @flow.bound
  private async *fetchJoinedDirectory(refQuery: TRefQuery) {
    try {
      if (isDynamicJoin(refQuery)) {
        return;
      }
      const res = await fetchJoinedDirectory(refQuery);
      yield;
      this.joinedObjects[JSON.stringify(refQuery)] = res;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  private getObjectTypesFormAttributtes = (attributes: TDirectoriesAttributesDictionary): string[] => {
    const refObjectTypes = new Set<string>();

    const getRefObjectTypes = (attr: TDirectoryTypeAttribute) => {
      if (attr.refObjectType) {
        refObjectTypes.add(attr.refObjectType);
      }

      if (attr.attributes) {
        attr.attributes.forEach((attr) => getRefObjectTypes(attr));
      }
    };

    for (const key in attributes) {
      getRefObjectTypes(attributes[key]);
    }

    return Array.from(refObjectTypes);
  };

  @flow.bound
  async *fetchTypesList() {
    try {
      const response = await getTypesList();
      yield;
      this.typesList = response;
    } catch (error) {
      yield;
      console.error(error);
      return;
    }
  }

  @flow.bound
  async *loadDirectoriesData() {
    if (this.isLoading) return;

    this.isLoading = true;

    try {
      const typesList = await getTypesList();
      yield;

      this.typesList = typesList;

      const attributesRes = await this.fetchAttributes(typesList);
      yield;

      this.attributes = attributesRes;

      const refObjectTypes = this.getObjectTypesFormAttributtes(attributesRes);
      /*
        TODO: Доработать логику получения типов объектов для получения константных справочников в том числе
        т.к. сейчас на константные справочники никто не ссылается, поэтому они не запрашиваются
      */
      refObjectTypes.push('Common_GeologicalLoad');

      const directoriesRes = await this.fetchDirectories(refObjectTypes);
      yield;

      this.directories = directoriesRes;
    } catch (error) {
      yield;
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *updateDirectories() {
    try {
      const refObjectTypes = this.getObjectTypesFormAttributtes(this.attributes);
      const directoriesRes = await this.fetchDirectories(refObjectTypes);
      yield;

      this.directories = directoriesRes;
    } catch (error) {
      yield;
      console.error(error);
    }
  }
}
