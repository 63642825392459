import {
  Active,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { createPortal } from 'react-dom';

import { Control } from '../../entities/abstract-entities';
import { RemovableRow } from '../../entities/control-entities/removable-row.entity';
import { FormStore } from '../../entities/form.entity';

import { RemovableRowItem } from './removable-row-item';
import { SectionDropdown } from './removable-row-item-dropdown';
import styles from './removable-row.module.scss';

type Props = {
  formStore: FormStore;
  item: RemovableRow;
  getLabel: (item: Control) => string | undefined;
};

export const RemovableRowControl = observer(function RemovableRowControl({ item, formStore, getLabel }: Props) {
  const [activeSection, setActiveSection] = useState<Active>();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const overlayItem = item.rows.find((row) => row.id === activeSection?.id);

  const handleDragStart = ({ active }: DragStartEvent): void => {
    setActiveSection(active);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent): void => {
    if (over && active.id !== over.id) {
      const oldIndex = item.rows.findIndex((row) => row.id === active.id);
      const newIndex = item.rows.findIndex((row) => row.id === over.id);
      item.setRows(arrayMove(item.rows, oldIndex, newIndex));
    }
  };

  const rows = item.filteredRows ? item.filteredRows : item.rows;

  return (
    <div className={styles.sectionsWrapper}>
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={toJS(item.rows)} strategy={verticalListSortingStrategy}>
          {rows.map((row) => (
            <RemovableRowItem
              key={row.id}
              item={row}
              isStatic={item.isStaticRows}
              deleteRow={item.deleteRow}
              getLabel={getLabel}
              formStore={formStore}
            />
          ))}
        </SortableContext>
        {createPortal(
          <DragOverlay>
            {overlayItem ? (
              <RemovableRowItem
                overlay
                isStatic={item.isStaticRows}
                item={overlayItem}
                deleteRow={item.deleteRow}
                getLabel={getLabel}
                formStore={formStore}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
      {!item.isStaticRows && (
        <SectionDropdown buttonLabel={item.buttonLabel} options={item.buttonOptions} onNewSection={item.addRow} />
      )}
    </div>
  );
});
