import { action, computed, makeObservable, observable } from 'mobx';

import { TRequiredIf } from 'src/api/directory/types';
import { hasValue } from 'src/shared/lib/common';
import { assert } from 'src/shared/utils/assert';
import { TAttrConcatRefs } from 'src/shared/utils/get-concated-name';
import { getRandomNumber } from 'src/shared/utils/get-random-number';
import { isStringNumberOrBoolean } from 'src/shared/utils/is-string-number-or-boolean';
import { Directories } from 'src/store/directories/directories.store';
import { TOption } from 'src/store/directories/types';

import { Control } from '../abstract-entities';
import { TControlValueType } from '../types';

type TLabelOptions = {
  label?: string;
  type: TControlValueType;
  formElementRefId?: string;
  fieldId?: string;
  requiredIf?: TRequiredIf[];
  delimiter?: string;
  attrConcat?: string[];
  attrConcatRefs?: TAttrConcatRefs;
  refObjectType?: string;
  refObjectAttr?: string;
  directories: Directories;
};

export class Label extends Control<string | number | null> {
  @observable valueWithLabel: TOption | null = null;

  private readonly directories: Directories;
  readonly isPrimitive: boolean;
  readonly delimiter?: string;
  readonly attrConcat?: string[];
  readonly attrConcatRefs?: TAttrConcatRefs;
  readonly refObjectType?: string;
  readonly refObjectAttr?: string;

  constructor(data: TLabelOptions) {
    super({ ...data, id: getRandomNumber() });
    this.isPrimitive = !data.formElementRefId;
    this.delimiter = data.delimiter;
    this.attrConcat = data.attrConcat;
    this.attrConcatRefs = data.attrConcatRefs;
    this.refObjectAttr = data.refObjectAttr;
    this.refObjectType = data.refObjectType;
    this.directories = data.directories;

    if (this.isPrimitive) {
      const label = data.label;

      assert(label, 'primitive label is not presented');

      this.valueWithLabel = {
        label,
        value: label,
      };
    }

    makeObservable(this);
  }

  private getLabelValueFromDirectory(value: string | number): TOption | null {
    const refObjectType = this.refObjectType;
    const refObjectAttr = this.refObjectAttr;

    assert(refObjectType && refObjectAttr, 'refObjectAttr or refObjectType is not presented');

    const directory = this.directories.getDirectory(refObjectType)?.find((dir) => dir.id === value);

    if (!directory) {
      return null;
    }

    const dirValue = directory.data[refObjectAttr];

    if (!dirValue || !isStringNumberOrBoolean(dirValue)) {
      return null;
    }

    return {
      label: dirValue.toString(),
      value,
    };
  }

  @computed
  get value(): string | number | null {
    return this.valueWithLabel?.value ?? null;
  }

  @action.bound
  resetControl(): void {
    this.valueWithLabel = null;
  }

  @action.bound
  setValueWithLabel(value: TOption | null): void {
    this.valueWithLabel = value;
  }

  @action.bound
  setValue(value: string | number | null): void {
    if (!hasValue(value)) {
      this.valueWithLabel = null;
      return;
    }

    if (!this.refObjectType && !this.refObjectAttr) {
      this.valueWithLabel = {
        label: value.toString(),
        value,
      };
      return;
    }

    this.valueWithLabel = this.getLabelValueFromDirectory(value);
  }
}
