import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { YearField } from 'src/features/directory-form/entities/control-entities/year-field.entity';
import { YearPickerControl } from 'src/features/directory-form/form-controls';
import styles from 'src/pages/directory/directory.module.scss';
import { DirectoryPageStore } from 'src/pages/directory/directory.store';
import { ReactComponent as BookCheckOutlineIcon } from 'src/shared/assets/icons/book-checked-outline.svg';
import { ReactComponent as BookCheckIcon } from 'src/shared/assets/icons/book-checked.svg';
import { ReactComponent as CheckIcon } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from 'src/shared/assets/icons/cross.svg';
import { ReactComponent as EditIcon } from 'src/shared/assets/icons/pencil-filled.svg';
import { ReactComponent as PlusIcon } from 'src/shared/assets/icons/plus.svg';
import { ReactComponent as BackIcon } from 'src/shared/assets/icons/round-arrow-left.svg';
import { Button } from 'src/shared/components/button';
import { FormDatePicker } from 'src/shared/components/form-components';
import Modal from 'src/shared/components/modal';
import { Tooltip } from 'src/shared/components/tooltip';
import { ROUTES } from 'src/shared/constants/routes';
import { useModal } from 'src/shared/hooks/use-modal';
import { MenuSubheaderLayout } from 'src/shared/layouts/menu-subheader-layout/menu-subheader-layout';
import { Directories } from 'src/store/directories/directories.store';
import { ThemeStore } from 'src/store/theme/theme-store';

import { ImportButton } from './import-button';

const defaultTime = moment().startOf('date');

const hasFrom = (obj: unknown): obj is { from: string } => {
  return !!obj && typeof obj === 'object' && 'from' in obj;
};

interface Props {
  pageStore: DirectoryPageStore;
  directories: Directories;
  theme: ThemeStore;
  allowEditing: boolean;
  onWarningModalOpen: VoidFunction;
  onRelatedDirectoriesSidebarOpen: VoidFunction;
}

export const DirectoryHeader = observer(function DirectoryHeader({
  pageStore,
  directories,
  theme,
  allowEditing,
  onWarningModalOpen,
  onRelatedDirectoriesSidebarOpen,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { directory } = useParams();

  const { table } = pageStore.directory;

  const redirectPath = hasFrom(location.state) ? location.state.from : ROUTES.all;
  const directoryType = directories.typesList.find((type) => type.name === directory);

  const {
    isOpen: isCopyModalOpen,
    openModal: openCopyModal,
    closeModal: closeCopyModal,
  } = useModal({ initialIsOpen: false });

  const handleBack = () => {
    if (pageStore.directory.table.isEditMode) {
      onWarningModalOpen();
    } else {
      navigate(redirectPath);
    }
  };

  const handleYearChange = (val: Moment | null) => {
    if (directory && val) {
      table.setFilters('year', [val.year()], false);
      table.resetTableData();

      table.fetchTableData();
    }
  };

  const handleCopy = () => {
    if (table.period) {
      pageStore.saveYearChanges(String(table.period.year()));
      closeCopyModal();
    }
  };

  const yearPickerItem = table.yearFilterItem;

  return (
    <>
      <MenuSubheaderLayout className={styles.subheader}>
        <div className={styles.leftSubheaderPart}>
          <Button variant="text" onClick={handleBack}>
            <BackIcon className={styles.backArrow} />
            {t('common:Buttons.back')}
          </Button>
          {directoryType?.label && <p className={styles.directoryName}>{directoryType.label}</p>}
        </div>

        {yearPickerItem && yearPickerItem instanceof YearField && (
          <YearPickerControl item={yearPickerItem} label={''} onChange={handleYearChange} className={styles.input} />
        )}

        {allowEditing && !table.isEditMode && (
          <Button
            className={styles.button}
            prefixIcon={<EditIcon />}
            variant="primary"
            onClick={() => table.setEditMode(true)}
          >
            {t('directory:editButton')}
          </Button>
        )}

        {table.isEditMode && (
          <>
            <ImportButton />

            <Button
              className={styles.addDrillingRig}
              variant="primary"
              prefixIcon={<PlusIcon />}
              onClick={pageStore.openSidebar}
            >
              {directoryType?.label
                ? t('directory:addNewDirectoryValue', { type: directoryType.label })
                : t('directory:Errors.unrecName')}
            </Button>
            {pageStore.directory.table.isCheckable && (
              <Button
                className={styles.addDrillingRig}
                variant="primary"
                prefixIcon={<PlusIcon />}
                onClick={openCopyModal}
              >
                {t('directory:copyToAnotherYear')}
              </Button>
            )}
            <Button
              className={styles.button}
              prefixIcon={<CrossIcon />}
              variant="danger"
              onClick={pageStore.resetDraft}
            >
              {t('directory:discardChangesButton')}
            </Button>
            <Button
              className={styles.button}
              prefixIcon={<CheckIcon />}
              variant="success"
              onClick={pageStore.saveChanges}
            >
              {t('directory:saveChangesButton')}
            </Button>
          </>
        )}

        <Tooltip text={t('directory:relatedDirectories')} placement="bottomRight">
          <button className={styles.relatedDirectoriesButton} onClick={onRelatedDirectoriesSidebarOpen}>
            {theme.theme === 'dark' ? (
              <BookCheckIcon className={styles.relatedDirectoriesIcon} />
            ) : (
              <BookCheckOutlineIcon />
            )}
          </button>
        </Tooltip>
      </MenuSubheaderLayout>
      {table.isEditMode && table.isCheckable && yearPickerItem && (
        <Modal
          isOpened={isCopyModalOpen}
          handleClose={closeCopyModal}
          title={t('directory:copyModalTitle')}
          closeOnClickOutside={false}
          showCloseCross={false}
        >
          <p className={styles.copyModalDescription}>{t('directory:copyModalDescription')}</p>
          <FormDatePicker
            allowClear
            value={table.period}
            defaultTime={defaultTime}
            defaultDate={defaultTime}
            className={clsx(styles.input, styles.modalDatepicker)}
            label="Год"
            picker="year"
            format="YYYY"
            onChange={table.setPeriod}
          />
          <div className={styles.modalButtonsWrapper}>
            <Button
              className={clsx(styles.button, styles.modalButton)}
              prefixIcon={<CrossIcon />}
              variant="danger"
              onClick={closeCopyModal}
            >
              {t('directory:discardChangesButton')}
            </Button>
            <Button
              className={clsx(styles.button, styles.modalButton)}
              prefixIcon={<CheckIcon />}
              variant="success"
              onClick={handleCopy}
            >
              {t('directory:saveChangesButton')}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
});
